@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
	height: 100%;
}

@font-face {
  font-family: "line-awesome";
  src: url("/line-awesome/line-awesome/fonts/line-awesome.ttf");
}

 html, body {
  height: 100%;
  width: 100vw;
  min-height: 0;
  font-size: 10px;
  font-family: "line-awesome";
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /*overflow-y: hidden;*/
}

.navbar {
  height: 50px;
  min-height: 50px;
  margin: 0;
  position: relative;
  z-index: 2;
  background-color: white;
}

.navbar::before {
  content: unset;
}

.navbar__wrapper {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.navbar__brand {
  font-size: 2rem;
  margin: 0;
  color: white;
  font-weight: bold;
  letter-spacing: -0.25rem;
}

.navbar__list > li {
  margin-left: 20px;
}

.navbar__list > li > a {
  color: white;
}

.Overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
  background-color: #ecf0f1;
}

.toolbar__heading {
  margin-top: 0;
}

.overview__toolbar__button:focus {
  outline: none;
}

.overview__toolbar__button--group:after {
  position: absolute;
  content: " ";
  border: 4px dashed white;
  border-radius: 5px;
  width: 18%;
  height: 60%;
}

.toolbar__heading {
  font-size: 3rem;
  font-family: sans-serif;
  text-align: left;
}

.Sidebar {
  width: 20%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  min-width: 300px;
  position: relative;
  z-index: 1000;
  justify-content: flex-start;
  align-items: center;
  background-color: white; 
  border-radius: 5px 0 0 5px;
  overflow: scroll;
  box-shadow: -2px 0px 5px 0px rgba(120,120,120,1);
} 

.Sidebar__item {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0;
  height: 7.5vh;
  max-height: 80px;
  background-color: transparent;
  /*border-bottom: 1px solid #247EC2;*/
  box-sizing: border-box;
}

.fixture-button {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: #212121;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Sidebar__item:hover {
  cursor: pointer;
  background-color: #D7DBDC;
  box-sizing: border-box;
}

.fixture-button:hover {
  cursor: pointer;
}

.fixture-button:focus {
  outline: none;
}

.fixture-button * {
  margin: 5px 0;
  width: 50%;
  text-align: left;
}

.fixture-button__label {
  font-size: 1.4rem;
  width: 100%;
  text-align: left;
}

.editor {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh; 
  min-height: 0;
  flex-grow: 1;
}

.editing-area {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  /*padding: 40px;*/
  height: 100vh;
  min-height: 0;
  overflow: hidden; 
  min-height: 0;
}

.toolbar {
  /*position: fixed;*/
  z-index: 100;
  width: 100px;
  min-width: 100px;
  background-color: white;
  top: 0;
  left: 0;
  box-sizing: border-box;
  /*padding: 20px 0;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  box-shadow: 1px 0px 5px 0px rgba(120,120,120,1);
}

.toolbar__button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toolbar__button {
  background-color: transparent;
  border: none;
  font-weight: bold;
  /*margin-bottom: 50px;*/
  height: 10vh;
  max-height: 80px;
  color: #3C3C3C; 
  font-size: 3rem;
}

.toolbar__button:hover {
  cursor: pointer;
  border-left: 3px solid #0D5E9A;
  color: #0D5E9A;
}

.toolbar__button:focus {
  outline: none;
}

.toolbar__button--add {
  
}

.toolbar__button--back {
  margin-bottom: 50px;
  border-bottom: 2px solid #eee;
}

.risers-container {
  flex-grow: 1;
  width: 90%;
  margin: 0 auto;
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  margin: 10px auto;
}

.scroll-button {
  border-radius: 5px;
  border: none;
  font-size: 3rem;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.scroll-button:hover {
  color: blue;
  cursor: pointer;
}

.scroll-button--right {
  position: fixed;
  z-index: 10000;
  right: 1.25%;
  top: 50%;
}

.scroll-button--left {
  position: fixed;
  z-index: 10000;
  left: 1.25%;;
  top: 50%;
}

.riser-wrapper {
  flex-grow: 1;
  background-color: #f7f7f7;
  overflow-y: scroll;
  background-color: #ecf0f1;
  padding: 30px;

}

.riser {
  display: flex;
  flex-direction: column-reverse;
  list-style-type: none;
  justify-content: center;
  flex-grow: 1;
  padding: 0;
  /*position: relative;*/
  /*z-index: 100;*/
  /*flex: 1 1 1000px;*/
  /*margin-bottom: 50px;*/
  overflow-y: scroll;
  margin: 0;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 1px 5px 0px rgba(120,120,120,1);
  position: relative;
}

/*.riser-editing-area {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: hidden;
  position: relative;
  height: 100%;
}*/

.floor-lines {
  width: 100%;
  position: absolute;
  top: 117px;
  box-sizing: border-box;
  overflow: hidden;
  /*margin-top: 10px;*/
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  height: 100%;
}

.floor-line {
  width: 100%;
  height: 100px;
  max-height: 100px;
  border-bottom: 1px solid lightgrey;
  box-sizing: border-box;
  max-height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;
}

.floor-line__label {
  margin: 0;
  margin-top: 1px;
  font-size: 1.5rem;
  color: #333;
  font-family: sans-serif;
  width: 75px;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  z-index: 5000;
}

.riser__toolbar-wrapper {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  /*position: absolute;*/
  overflow-x: hidden;
  min-height: 150px;
  top: 10px;
  z-index: 1000;
}

.label__text {
  margin: 0;
}

.floor {
  border-bottom: 2px solid grey;
  display: flex;
  align-items: flex-end;
  min-height: 120px;
  /*height: 7.5vh;*/
  /*height: auto;*/
  /*height: 100%;*/
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #F4F5F5;
}

.floor--source {
  border-bottom: 2px solid red;
}

.floor__total-info {
  width: 100px;
  /*height: 100%; */
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  box-sizing: border-box;
}

.floor__main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /*border-left: 4px solid red;*/
  height: 100%;
  width: 100%;
  min-height: 150px;
  padding: 10px 0;
  box-sizing: border-box;
  position: relative;
}

.floor__input {
  width: 100%;
  text-align: right;
}

.floor__toolbar {
  /*position: absolute;
  right: 0;
  top: 0;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /*height: 100%;*/
  padding: 5px;
  box-sizing: border-box;
  width: 7vw;
  max-width: 120px;
  min-height: 100px;
  align-self: stretch;
  z-index: 10;
}

.floor__toolbar__button {
  font-size: 2rem;
  background-color: transparent;
  border: none;
  color: #ACAFB0;
}

.floor__toolbar__button:hover {
  cursor: pointer;
  color: #0D5E9A;
}

.floor__toolbar__button--hot:hover {
  color: red;
}

.floor--cold-source .floor__toolbar__button--cold {
  color: blue;
}

.floor--hot-source .floor__toolbar__button--hot {
  color: red;
}

.floor__total-info {
  /*height: 100%;*/
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
  padding-bottom: 5px;
  width: 5vw;
  max-width: 150px;
}

.floor__total-info__helper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-grow: 1;
}

.floor__total-info__values {
  margin: 0;
  /*margin-bottom: 20px;*/
  font-size: 1.4rem;
}

.floor__total-info__column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;  
  max-height: 120px;
}

.toolbar__info {
  display: flex;
  flex-direction: column;
}

.toolbar__info__text {
  margin: 0;
  font-size: 1.5rem;
}

.toolbar__info__text--cold {
  color: #3867d6;
}

.toolbar__info__text--hot {
  color: #d63031;
}

.toolbar__info__text--total {
  color: #6c5ce7;
}

.floor-fixtures {
  display: flex;
  flex-direction: row;
  height: 100%;
  /*width: 100%;*/
  flex-grow: 1;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-top: 10px;
  position: relative;
  min-height: 100px;
}

.fixture {
  /*height: 100%;*/
  height: 7.5vh;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 10px;
}

.fixture * {
  margin: 0;
}

.fixture__label {
  font-size: 1.6rem;
} 

.fixture__info {
  font-size: 2rem;
}
.Home {
	background-color: white;
	flex-grow: 1;
}

/*f6faff*/

.Home .lander {
  padding: 0;
  text-align: left;
  position: relative;	
	height: 100%;
	width: 100vw;
	min-height: 100vh;
}

.lander__container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.Home .navbar {
	background-color: transparent;
}



.navbar__list li a {
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 1rem;
}

.navbar__link--signup a {
	background-color: #1dd1a1;
}

.navbar__link--login a {
	background-color: #8395a7;
}

.navbar__link:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

.navbar__link a:hover {
	text-decoration: none;
}

.lander__header {
	height: 70vw;
	max-height: 50vh;
	position: relative;
	background-image: url(https://res.cloudinary.com/rezdelamasa/image/upload/v1568779719/riser-sizer-bg.svg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 60% 20%;
	display: flex;
	flex-direction: column;
}

.lander__header img {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.lander__text {
	width: 80%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
	justify-content: center;
	flex: 100% 1 1;
	height: auto;
}

.lander__heading {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 3rem;
  letter-spacing: -1px;
	margin: 0 auto;
  color: white;
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
  text-shadow: 0px 1px 2px rgba(0,0,0,0.2);
}

.Home .lander__header p {
  font-size: 1.6rem;
  color: white;
  width: 100%;
	text-align: left;
	align-self: flex-start;
	margin-top: 2vw;
}

.lander__background {
	position: fixed;
	z-index: 0;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	width: 300%;
	top: -480%; left: -50%;
	background-repeat: repeat;
	background-position: bottom;
	background-size: 5%;
	height: 500%;
}

.lander__wrapper {
	width: 80%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 100px 0 100px 0;
}

.lander__column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-grow: 1;
	box-sizing: border-box;
}

.lander__column--text {
	max-width: 40%;
}

.lander__image {
	margin: 0 auto;
	height: 100%;
	object-fit: contain;
	border-radius: 5px;
	overflow: hidden;
}

.dashboard {
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	display: flex;
}

.page-header {
	border: 0;
	margin: 0;
}

.page-header h1 {
	margin: 0;
}

.dashboard__content {
	padding-top: 80px;
	box-sizing: border-box;
	background-color: #ecf0f1;
	flex-grow: 1;
	padding: 80px 5% 0 5%;
}

.Menu__button--active {
	color: #4d83fe!important;
	text-decoration: none;
}

.subsection__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 50px;
	box-sizing: border-box;
	padding: 0 1.65%;
}

.dashboard__subheading {
	margin: 0;
	font-size: 3.2rem;
	font-weight: bold;
	color: #4d83fe;
}

.subsection__link {
	font-size: 1.8rem;
	padding: 10px 20px;
	border: 2px solid #6D99FE;
	/*background-color: #DEE8FE;*/
	box-sizing: border-box;
	border-radius: 50px;
	color: #6D99FE;
}

.subsection__link:hover {
	border: 2px solid #4d83fe;
	background-color: #4d83fe;
	text-decoration: none;
	color: white;
}

.projects__list {
	display: flex;
	margin-top: 30px;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.list-group-item {
	/*width: 20vw;*/
	flex: 20% 0 1;
	margin: 0 1.65%;
	border: none;
	border-radius: 10px;	
	margin-bottom: 3.3%;
	display: flex;
	flex-direction: column;
}

.list-group-item h4 {
	font-size: 2.2rem;
	margin-bottom: 1vh;
	margin-top: 10px;
	font-weight: bold;
	color: #444!important;
}

.list-group-item-text {
	display: flex;
	position: relative;
	flex-direction: column;
	flex-grow: 1;
	flex: 100% 1 1;
	justify-content: space-between;
	font-size: 1.4rem;
	color: #747B7D;
}

.project__address {
}

.project__date {
	position: relative;
	bottom: 0;
	align-self: flex-end;
	flex-grow: 1;
	flex: 100% 1 1;
	display: flex;
	align-items: flex-end;
	margin: 0;
	color: #8D9698;
	font-size: 1rem;
}

.list-group-item:hover {
	background-color: white!important;
	border-bottom: 4px solid #ccc;
	-webkit-transform: translateY(-4px);
	        transform: translateY(-4px);
	color: white!important;
}

.features {
	background-color: #fafafa;
	flex: 100% 1 1;
}

.features__heading {
	font-size: 2.8rem;
	margin: 0 auto;
	font-weight: bold;
	text-align: center;
	margin-bottom: 100px;
	color: #666;
	margin-top: 100px;
	width: 80%;
}

.features__row {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 84%;
	margin: 0 auto;
	margin-top: 50px;
}

.feature {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	margin: 0 auto;
	margin-bottom: 50px;
	flex-wrap: nowrap;
	/*background-color: white;
	box-sizing: border-box;
	padding: 40px 20px;
	border-radius: 10px;
	box-shadow: 0 8px 14px rgba(0,0,0,0.07);*/
}

.feature__heading {
	color: #4D83FE;
	/*color: #333;*/
	font-size: 1.8rem;
	font-weight: bold;
	text-align: center;
}

.feature__icon {
	width: 25vw; height: 25vw;
	max-width: 200px;
	max-height: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
}

.feature__text {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.feature__description {
	text-align: center;
	font-size: 1.2rem;
	color: #444;
	max-width: 60%;
}

@media screen and (min-width: 768px) {
		
	.navbar {
		height: 100px;
	}

	.navbar__brand {
		font-size: 3.5rem;
	}

	.navbar__list > .navbar__link > a {
		height: 80px;
		font-size: 2rem;
		padding: 10px 20px;
	}

	.feature__text {
		justify-content: flex-start;
	}

	.features__row {
		flex-direction: row;
	}

	.feature {
		width: 50%;
	}

	.feature__icon {
		width: 20vw;
		height: 20vw;
	}

	.features__heading {
		font-size: 3.5rem;
	}

	.lander__header {
		max-height: 70vh;
	}

	.lander__heading {
		font-size: 5rem;
	}

	.Home .lander__header p {
		font-size: 2rem;
	}
}

@media screen and (min-width: 1200px) {

	.lander__heading {
		font-size: 7rem;
	}

 	.features__row {
 		flex-direction: row;
 		flex-wrap: wrap;
 	}

 	.features__heading {
 		font-size: 5rem;
 	}

	.feature {
		width: 20%;
	}

	.feature__icon {
		width: 10vw; height: 10vw;
	}

	.feature__description {
		max-width: 100%;
		font-size: 1.6rem;
	}

	.navbar__wrapper, .lander__text, .features__row {
		max-width: 1400px;
	}

	.features__row {
		max-width: 1500px;
	}

	.Home .lander__header p {
	  font-size: 4rem;
	}

}

@media screen and (min-width: 1600px) {
	
}


.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

.Login {
	flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #63a4ff;
  position: relative;
} 

.Login__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.Login__header {
  display: flex;
  height: 70px;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.Login__header a {
  font-size: 3rem;
  color: white;
  font-weight: bold;
  letter-spacing: -0.25rem;
}

.Login__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20vh;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.Login h1 {
  font-weight: bold;
}

.LoaderButton[disabled] {
  background-color: white;
  opacity: 1;
  border: none;
  font-weight: bold;
  color: #888;
  padding: 8px 25px;
}

.LoaderButton {
  background-color: white;
  opacity: 1;
  border: none;
  font-weight: bold;
  color: #666;
  padding: 8px 25px;
}

.LoaderButton:hover {
  color: #63a4ff;
  background-color: white;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

@media all and (min-width: 480px) {
  .Login__content {
    padding: 20vh 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 480px;
  }
}

.Login form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: unset;
  background-color: white;
  border-radius: 10px;
  padding: 50px;
}

.form__heading {
  color: #333;
}

.form-group {
  height: 80px!important;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px!important;
  width: 100%;
}

.form-control {
  border: none;
  box-shadow: none;
  background-color: white;
  border-bottom: 2px solid #c8d6e5;
  box-sizing: border-box;
  margin: 0;
}

.control-label {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: white;
}

.form-control:focus {
  box-shadow: none;
}

.Login .LoaderButton {
  align-self: flex-end;
}


.NotFound {
  padding-top: 100px;
  text-align: center;
}

@media all and (min-width: 480px) {
  .Signup {
    /*padding: 60px 0;*/
  }

  .Signup form {
    margin: 0 auto;
    max-width: 480px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.Signup {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #63a4ff;
  position: relative;
} 

.Signup__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.Signup__header {
  display: flex;
  height: 70px;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.Signup__header a {
  font-size: 3rem;
  color: white;
  font-weight: bold;
  letter-spacing: -0.25rem;
}

.Signup form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: unset;
  z-index: 1;
  position: relative;
  margin-top: 10vh;
  background-color: white;
  padding: 50px;
  box-sizing: content-box; 
  border-radius: 10px;
}

.form-group {
  height: 80px!important;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px!important;
  width: 100%;
}

.form-control {
  border: none;
  box-shadow: none;
  background-color: #eee;
  border-bottom: 2px solid #c8d6e5;
  box-sizing: border-box;
  margin: 0;
}

.form-control:focus {
  background-color: #DEE8FE;
}

.control-label {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #333;
}

.form-control:focus {
  box-shadow: none;
}

.Signup .LoaderButton {
  align-self: flex-end;
}

.form__heading {
  text-align: left;
  width: 100%;
  margin-bottom: 50px;
}

.App {
	height: unset;
	min-height: 100vh;
}

.NewProject {
	display: flex;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	justify-content: center;
	align-items: flex-start;
	background-color: #62a3ff;
	padding: 0 25%;
	background-image: url(https://res.cloudinary.com/rezdelamasa/image/upload/v1568779719/riser-sizer-bg.svg);
	background-size: 100%;
	background-position: 0% 0%;
	background-repeat: no-repeat;
}

.NewProject form {
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  height: unset;
  margin: 20vh auto;
  align-items: flex-start;
  background-color: white;
  padding: 100px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,.5);
}

.NewProject h1 {
	margin-bottom: 50px;
	font-weight: bold;
	color: #444;
}

.NewProject .form-control {
  border: none;
  box-shadow: none;
  background-color: #eee;
  border-bottom: 2px solid #c8d6e5;
  box-sizing: border-box;
  margin: 0;
}

.NewProject .form-control:focus {
  background-color: #DEE8FE;
}

.NewProject form text {
  height: 300px;
  font-size: 24px;
}

.NewProject__buttons {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0;
}

.NewProject__cancel {
	font-size: 1.4rem;
}

.building-settings {
	width: 100%;
}	

.building-settings__button {
	width: 40px; height: 40px;
	border-radius: 5px;
	background-color: #eee;
	border: 2px solid #bbb;
	font-size: 2rem;
	color: #555;
	box-sizing: border-box;
	padding-top: 5px;
}

.building-settings__button * {
	pointer-events: none;
}

.building-settings__enable {
	margin-bottom: 50px;
}

.building-settings__content {
	border-top: 2px solid #ccc;
	padding: 50px 0;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.building-settings__group {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

}

.building-settings__group .building-settings__item {
	flex: 45% 0 1;
}

.building-settings__item {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.building-settings__item .form-group {
	height: auto!important;
}
 
.building-settings__description {
	font-size: 1.6rem;
	color: #777;
	flex: 100% 1 1;
}

.control-label {
	font-size: 1.6rem;
}

.label {
  height: 50px;
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  background-color: white;
  min-width: 50px;
  margin-bottom: 25px;
  margin-top: 25px;
  color: #4d83fe;
  font-family: "Lato", sans-serif;
  font-weight: bold;
}

.label__text {
  font-weight: 700;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 3rem;
  border-bottom: 2px solid rgb(220,220,220);
  width: 100%;
  padding-bottom: 10px;
}

.label__form {
  width: 100%;
}

.label__input {
  width: 100%;
  height: 100%;
  font-size: 2rem;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  border-bottom: 2px solid #4d83fe;
}

.label__input::-webkit-inner-spin-button,
.label__input::-webkit-outer-spin-button {
  -webkit-appearance: none; 
  margin: 0; 
}
* {
  font-family: "Lato", sans-serif;
}

.riser-helper {
  width: 100%;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: visible;
  position: relative;
  font-family: "Lato", sans-serif;
  height: 100%;
  box-sizing: border-box;
}

.riser__subheading {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  color: #4D5253;
}

.riser__content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0 60px 0 20px;
}

.riser-text__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 15%;
}

.riser__text {
  font-size: 1.5rem;
  margin: 10px 0 0 0;
  color: #8D9698;
}

.riser__button-wrapper {
  flex: 12% 0;
  display: flex;
  align-items: center;
}

.button--edit {
  width: 3vw;
  height: 3vw;
  margin: 0 auto;
  border: none;
  border-radius: 5px;
  bottom: 0;
  background-color: #4d83fe;
  font-size: 1.8rem;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(77,131,254, 0.5);
  display: flex;
  align-items: center;
  color: white;
  box-sizing: border-box;
  justify-content: center;
}

.button--edit:hover {
  cursor: pointer;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 4px 5px rgba(77,131,254, 0.5);
}

.button--delete {
  background-color: transparent;
  color: #8D9698;
  margin: 0 auto;
  border: none;
  font-size: 2.8rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

.button--delete:hover {
  color: #e74c3c;
}

.button__arrow {
  margin: 0;
  background-color: #406CD0;
  border-radius: 100%;
  height: 30px; width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.riser-item__info {
	width: 100%;
	flex-grow: 1;
	box-sizing: border-box;
	height: 100%;
}
.riser__toolbar {
	flex: 20% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  height: 100px;
}
.riser-item {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  /*top: 160px;*/
  background-color: white;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
}

.riser-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.riser-item:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.riser-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  top: 0;
  margin-top: 0;
  width: 90%;
  padding: 0 10px;
  margin: 0 auto;
  align-self: flex-end;
  /*overflow-y: visible;*/
	padding: 0;
	border-radius: 5px;
	flex-grow: 1;
	height: unset;
	margin-bottom: 50px;
}

.riser-list--empty {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 5rem;
	box-sizing: border-box;
	margin: 0;
	color: rgb(200,200,200);
}
.risers-container {
	overflow-y: hidden;
	width: 100%;
	align-items: center;
	margin: 0 auto;
	margin-top: 50px;
	overflow-x: hidden;
	justify-content: flex-start;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
}
.Overview {
	flex-grow: 1;
	height: unset;
	width: 100%;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: hidden;
}

.overview__toolbar {
	width: 100%;
	height: 8vh;
	max-height: 150px;
	min-height: 60px;
	display: flex;
	padding: 0;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: flex-end;
	background-color: #ecf0f1;
	max-width: 100%;
	width: 90%;
	margin: 0 auto;
	flex-shrink: 0;
}

.overview__toolbar-wrapper {
	height: 80%;
	width: 100%;
	box-sizing: border-box;
	display: flex;	
	flex: 100% 1 1;
	justify-content: space-between;
}

.overview__back {
	width: 70px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	color: #333;
	box-sizing: border-box;
	line-height: 70px;
}

.overview__header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.overview__heading {
	margin: 0;
	font-size: 3rem;
	font-family: "Lato", sans-serif;
	font-weight: bold;
	height: 50px;
	display: flex;
	align-items: center;
}

.overview__subheading {
	color: #8D9698;
	font-size: 1.4rem;
}

form {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
	height: 100%;
}

.form-group {
	height: 100%;
	margin: 0;
}

.form-control {
	height: 100%;
}

.overview__buttons {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: space-between;
}

.overview__buttons-wrapper {
	height: 50px;
	position: relative;
}

.overview__toolbar__button {
	padding: 0;
	height: 100%;
}

.overview__button--delete {
	background-color: transparent;
	height: 50px; width: 50px;
	border: none;
	font-size: 2rem;
	margin: 0;
	margin-top: -10px;
	padding: 0;
	margin-left: 50px;
	color: #777;
}

.overview__button--delete:hover {
	color: white;
	background-color: #e17055;
}

.overview__button--delete:focus {
	background-color: transparent;
	outline: none;
}

.overview__toolbar__button {
	/*width: 8vw;*/
	height: 50px;
	font-size: 2rem;
	font-family: "Lato", sans-serif;
	/*box-shadow: none;	*/
	border-radius: 0;
	height: 50px;
	background-color: transparent;
	border: 0;
	color: #555;
	padding: 0 10px;
	margin-left: -10px;
	border-radius: 5px;
}

.overview__toolbar__button:hover {
	cursor: pointer;
	color: white;
	background-color: #4d83fe;
}
.toolbar__button--edit {
	color: blue;
}
.toolbar__label {
	font-size: 3rem;
	text-align: center;
}
.floor__form {
	height: unset;
}

.floor__input {
	border: 0;
	background-color: transparent;
	border-bottom: 2px solid #4d83fe;
}

.floor__input::-webkit-inner-spin-button,
.floor__input::-webkit-outer-spin-button {
	-webkit-appearance: none; 
  margin: 0; 
}
.floor__total-info__values {
	font-size: 2rem;
	color: #555;
}

.floor__label {
  width: 100%;
  margin: 0;
  /*margin-top: 20px;*/
  align-self: flex-end;
  text-align: right;
  font-size: 2.8rem;
}

.fixture {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	background-color: #4d83fe;
	padding: 10px 20px 0 20px;
	border-radius: 2px;
	height: 100%;
	color: white;
	box-shadow: 0px 2px 5px 1px rgba(100,100,100,1);
	/*margin-bottom: 20px;*/
	max-height: unset;
	max-width: 120px;
	width: unset;
	position: relative;
	min-height: 120px;
	margin: 10px;
}

.fixture--delete {
	background-color: #e74c3c;
}

.fixture--delete .fixture__info {
	background-color: #c0392b;
}

.fixture__label {
	text-align: center;
}

.fixture__info {
	background-color: #406CD0;
	padding: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	font-size: 0.8vw;
}

.fixture__type {
	font-size: 1.5rem;
	text-align: center;
}

.floor--active .fixture__delete {
	display: flex;
	position: absolute;
	top: 0;
	right: 5px;
	background-color: transparent;
	border: none;
	color: white;
	font-size: 2rem;
}

.fixture__delete {
	display: none;
}

.fixture__delete:focus {
	outline: none;
}
.floor-fixtures {
	height: 100%;
	box-sizing: border-box;
	padding: 10px 20px;
}
.floor__toolbar__buttons {
	display: flex;
	flex-direction: row;
}

.floor__toolbar__button {
	display: flex;
	justify-content: center;
	align-items: center;
}
.floor__toolbar {
	min-width: 150px;
}
.floor__main {
	height: 100%;
	/*min-height: 100%;*/
}

.floor__main::after {
	pointer-events: none;
}
.floor {
	min-height: 150px;
	box-sizing: border-box;
}

.floor--active {
  background-color: white;
  box-shadow: 0px 0px 5px 2px rgba(150,150,150,1);
  position: relative;
  z-index: 2;
  border-radius: 5px;
}

.floor--selected {
	background-color: #CEDDFE;
	position: relative;
}
.riser {

}

@media print {
  .riser {
    position: absolute;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    position: fixed;
		z-index: 100000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-end;
  }

  .toolbar {
  	visibility: hidden;
  }

  .Overview {
  	visibility: hidden;
  }

  .overview__toolbar {
  	visibility: hidden;
  }

  .Sidebar {
  	visibility: hidden;
  }

  .fixture__info {
  	display: none;
  }

  .fixture__type {
  	display: none;
  }

  .fixture__label {
  	height: 10px;
  }

  .fixture__delete {
  	display: none!important;
  	position: fixed;
  	top: -100vw;
  }

  .fixture {
  	height: 40px!important;
  	min-height: unset;
  	min-width: unset;
  	margin-top: 10px!important;
  	display: flex;
  	justify-content: center;
  }

}
.riser-wrapper {
	height: 100%;
	overflow-y: scroll;
	padding: 30px;
}
.editing-area {
	height: 100%;
	overflow: visible;
}
.Sidebar__item {
	height: 150px;
	max-height: unset;
	min-height: 70px;
}
.Sidebar {
	overflow-y: scroll;
	height: 100%;
	box-sizing: border-box;
}
.editor {
	overflow-y: hidden;
	flex-grow: 1;
	height: unset;
	min-height: unset;
	box-sizing: border-box;
	height: 100%;
	position: absolute;
	height: 100vh;
	top: 0;
	background-color: #ecf0f1;
	z-index: 100;
}
.Projects {
	height: 100vh; width: 100vw;
	position: fixed;
	top: 0;
	box-sizing: border-box;
	z-index: 3;
	display: flex;
}

.Projects form textarea {
  height: 300px;
  font-size: 24px;
}

.Projects__wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	background-color: #ecf0f1;
	flex-grow: 1;
	overflow-x: hidden;
}

.Projects__Menu {
	width: 10vw;
	min-width: 250px;
	background-color: white;
	display: flex;	
	flex-direction: column;
	align-items: center;
	position: relative;
	z-index: 5;
}

.Menu__wrapper {
	width: 70%;
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.Menu__account {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
	text-align: center;
}

.Menu__avi {
	width: 40px;
	height: 40px;
	background-color: red;
	border-radius: 5px;
	margin-bottom: 20px;
}

.Menu__name {
	margin: 0;
	font-size: 1.6rem;
	font-weight: bold;
}

.Menu__button {
	margin: 25px 0;
	font-size: 1.8rem;
	color: #8D9698;
	font-weight: bold;
	background-color: transparent;
	border: none;
	text-align: left;
	padding: 0;
}

.Menu__button:hover {
	color: #4d83fe;
	cursor: pointer;
	text-decoration: none;
}


.BillingFormContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  padding-top: 50px;
  padding-bottom: 20px;
  position: relative;
}

.BillingForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.BillingForm h1 {
  width: 100%;
  text-align: left;
}

.BillingForm .card-field {
  margin-bottom: 15px;
  background-color: white;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #CCC;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  line-height: 1.3333333;
  width: 100%;
}

.BillingForm .card-field.StripeElement--focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  border-color: #66AFE9;
}

.control-label {
	text-align: left;
	width: 100%;
}

.BillingForm .LoaderButton {
  width: auto;
  padding: 10px 20px;
  align-self: flex-end;
  background-color: #4d83fe;
  color: white;
  margin: 20px 0;
}

.BillingForm .LoaderButton:disabled {
  background-color: #849DD8;
  color: #eee;
}

.BillingForm__cancel {
  margin: 0 auto;
  height: auto; 
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  position: absolute; 
  top: 0;
  right: 1rem;
  font-size: 3rem;
}

.Settings {
	display: flex;
	flex-direction: row;
	height: 100%;
}

.Settings__content {
	background-color: #ecf0f1;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding: 80px 10% 0 10%;
	overflow-y: scroll;
}

.Settings__heading {
	margin: 0;
	margin-bottom: 50px;
	font-size: 4rem;
	font-weight: bold;
	color: #444;
}

.Settings__subheading {
	font-weight: bold;
	color: #4d83fe;
}

.Settings__description {
	font-size: 2.4rem;
	font-style: italic;
	font-weight: normal;
	color: #777;
	margin-top: 20px;	
}

.Settings form {
	width: 80%;
	margin: 0 auto;
	max-width: unset;
	display: flex;
	flex-direction: column;
	height: unset;
	background-color: white;
}

.Subscription {
	width: 100%;
	margin: 0 auto;
}

.Subscription__table {
	width: 100%;
	display: table;
	background-color: white;
	border-radius: 10px;
	overflow: hidden;
}

.Subscription__table tr {
	height: 60px;
}

.Subscription__table td {
	box-sizing: border-box;
	text-align: center;
	font-size: 1.4rem;
}

.Subscription__column {
}

td:first-child {
	text-align: left;
	box-sizing: border-box;
	padding: 0 15px;
}

tr:nth-child(even) {
	background-color: rgba(150,150,150,0.1);
}

.Subscription__labels {
	text-align: left;
}

.Subscription__free {
	background-color: #b0bec5;
	width: 25%;
	color: white;
}

.Subscription__heading {
	color: #4d83fe;
	font-weight: bold;
}

.Subscription__content {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}

.Subscription p {
	font-size: 1.8rem;
	margin: 0;
}

.Subscription__button {
	font-size: 2rem;
	background-color: #4d83fe;
	color: white;
	border-radius: 10px;
	border: none;
	margin-left: 50px;
	padding: 0 20px;
	max-height: 50px;
}

.Subscription__button:hover {
	box-shadow: 0 2px 4px rgba(0,0,0,0.5);
	-webkit-transform: translateY(-2px);
	        transform: translateY(-2px);
}

.Table__heading {
	background-color: rgba(120,120,120,0.1);
	font-size: 1.6rem;
	font-weight: bold;
	color: white;
}

.Subscription__basic {
	background-color: #82b1ff;
	width: 25%;
}

.Subscription__plus {
	background-color: #a5d6a7;
	width: 25%;
}

.purchase-button {
	padding: 8px 15px;
	font-size: 1.4rem;
	border-radius: 20px;
	border: none;
	color: white;
}

.purchase-button--basic {
	background-color: #1e88e5;
}

.purchase-button--plus {
	background-color: #66bb6a;
}

.purchase-button:hover {
	box-shadow: 0 1px 2px rgba(0,0,0,0.5);
	-webkit-transform: translateY(-2px);
	        transform: translateY(-2px);
}

.Features {
	margin-top: 50px;
}

.Features ul {
	list-style-type: none;
	padding: 0;
	margin-top: 50px;
}

.Features li {
	margin: 40px 0;
}

.Features h2 {
	font-weight: bold;
	color: #4d83fe;
}

.Features h3 {
	
}

.Features h4 {
	font-size: 2.2rem;
	font-weight: bold;
	color: #555;
}

.Features p {
	font-size: 1.8rem;
}
