.Settings {
	display: flex;
	flex-direction: row;
	height: 100%;
}

.Settings__content {
	background-color: #ecf0f1;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding: 80px 10% 0 10%;
	overflow-y: scroll;
}

.Settings__heading {
	margin: 0;
	margin-bottom: 50px;
	font-size: 4rem;
	font-weight: bold;
	color: #444;
}

.Settings__subheading {
	font-weight: bold;
	color: #4d83fe;
}

.Settings__description {
	font-size: 2.4rem;
	font-style: italic;
	font-weight: normal;
	color: #777;
	margin-top: 20px;	
}

.Settings form {
	width: 80%;
	margin: 0 auto;
	max-width: unset;
	display: flex;
	flex-direction: column;
	height: unset;
	background-color: white;
}

.Subscription {
	width: 100%;
	margin: 0 auto;
}

.Subscription__table {
	width: 100%;
	display: table;
	background-color: white;
	border-radius: 10px;
	overflow: hidden;
}

.Subscription__table tr {
	height: 60px;
}

.Subscription__table td {
	box-sizing: border-box;
	text-align: center;
	font-size: 1.4rem;
}

.Subscription__column {
}

td:first-child {
	text-align: left;
	box-sizing: border-box;
	padding: 0 15px;
}

tr:nth-child(even) {
	background-color: rgba(150,150,150,0.1);
}

.Subscription__labels {
	text-align: left;
}

.Subscription__free {
	background-color: #b0bec5;
	width: 25%;
	color: white;
}

.Subscription__heading {
	color: #4d83fe;
	font-weight: bold;
}

.Subscription__content {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}

.Subscription p {
	font-size: 1.8rem;
	margin: 0;
}

.Subscription__button {
	font-size: 2rem;
	background-color: #4d83fe;
	color: white;
	border-radius: 10px;
	border: none;
	margin-left: 50px;
	padding: 0 20px;
	max-height: 50px;
}

.Subscription__button:hover {
	box-shadow: 0 2px 4px rgba(0,0,0,0.5);
	transform: translateY(-2px);
}

.Table__heading {
	background-color: rgba(120,120,120,0.1);
	font-size: 1.6rem;
	font-weight: bold;
	color: white;
}

.Subscription__basic {
	background-color: #82b1ff;
	width: 25%;
}

.Subscription__plus {
	background-color: #a5d6a7;
	width: 25%;
}

.purchase-button {
	padding: 8px 15px;
	font-size: 1.4rem;
	border-radius: 20px;
	border: none;
	color: white;
}

.purchase-button--basic {
	background-color: #1e88e5;
}

.purchase-button--plus {
	background-color: #66bb6a;
}

.purchase-button:hover {
	box-shadow: 0 1px 2px rgba(0,0,0,0.5);
	transform: translateY(-2px);
}

.Features {
	margin-top: 50px;
}

.Features ul {
	list-style-type: none;
	padding: 0;
	margin-top: 50px;
}

.Features li {
	margin: 40px 0;
}

.Features h2 {
	font-weight: bold;
	color: #4d83fe;
}

.Features h3 {
	
}

.Features h4 {
	font-size: 2.2rem;
	font-weight: bold;
	color: #555;
}

.Features p {
	font-size: 1.8rem;
}