.floor__total-info__values {
	font-size: 2rem;
	color: #555;
}

.floor__label {
  width: 100%;
  margin: 0;
  /*margin-top: 20px;*/
  align-self: flex-end;
  text-align: right;
  font-size: 2.8rem;
}
