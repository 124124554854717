* {
  font-family: "Lato", sans-serif;
}

.riser-helper {
  width: 100%;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: visible;
  position: relative;
  font-family: "Lato", sans-serif;
  height: 100%;
  box-sizing: border-box;
}

.riser__subheading {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  color: #4D5253;
}

.riser__content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0 60px 0 20px;
}

.riser-text__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 15%;
}

.riser__text {
  font-size: 1.5rem;
  margin: 10px 0 0 0;
  color: #8D9698;
}

.riser__button-wrapper {
  flex: 12% 0 0;
  display: flex;
  align-items: center;
}

.button--edit {
  width: 3vw;
  height: 3vw;
  margin: 0 auto;
  border: none;
  border-radius: 5px;
  bottom: 0;
  background-color: #4d83fe;
  font-size: 1.8rem;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(77,131,254, 0.5);
  display: flex;
  align-items: center;
  color: white;
  box-sizing: border-box;
  justify-content: center;
}

.button--edit:hover {
  cursor: pointer;
  transform: translateY(-2px);
  box-shadow: 0 4px 5px rgba(77,131,254, 0.5);
}

.button--delete {
  background-color: transparent;
  color: #8D9698;
  margin: 0 auto;
  border: none;
  font-size: 2.8rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

.button--delete:hover {
  color: #e74c3c;
}

.button__arrow {
  margin: 0;
  background-color: #406CD0;
  border-radius: 100%;
  height: 30px; width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}