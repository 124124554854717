.floor__form {
	height: unset;
}

.floor__input {
	border: 0;
	background-color: transparent;
	border-bottom: 2px solid #4d83fe;
}

.floor__input::-webkit-inner-spin-button,
.floor__input::-webkit-outer-spin-button {
	-webkit-appearance: none; 
  margin: 0; 
}