.Login {
	flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #63a4ff;
  position: relative;
} 

.Login__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.Login__header {
  display: flex;
  height: 70px;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.Login__header a {
  font-size: 3rem;
  color: white;
  font-weight: bold;
  letter-spacing: -0.25rem;
}

.Login__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20vh;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.Login h1 {
  font-weight: bold;
}

.LoaderButton[disabled] {
  background-color: white;
  opacity: 1;
  border: none;
  font-weight: bold;
  color: #888;
  padding: 8px 25px;
}

.LoaderButton {
  background-color: white;
  opacity: 1;
  border: none;
  font-weight: bold;
  color: #666;
  padding: 8px 25px;
}

.LoaderButton:hover {
  color: #63a4ff;
  background-color: white;
  transform: scale(1.02);
}

@media all and (min-width: 480px) {
  .Login__content {
    padding: 20vh 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 480px;
  }
}

.Login form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: unset;
  background-color: white;
  border-radius: 10px;
  padding: 50px;
}

.form__heading {
  color: #333;
}

.form-group {
  height: 80px!important;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px!important;
  width: 100%;
}

.form-control {
  border: none;
  box-shadow: none;
  background-color: white;
  border-bottom: 2px solid #c8d6e5;
  box-sizing: border-box;
  margin: 0;
}

.control-label {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: white;
}

.form-control:focus {
  box-shadow: none;
}

.Login .LoaderButton {
  align-self: flex-end;
}

