.App {
	height: unset;
	min-height: 100vh;
}

.NewProject {
	display: flex;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	justify-content: center;
	align-items: flex-start;
	background-color: #62a3ff;
	padding: 0 25%;
	background-image: url(https://res.cloudinary.com/rezdelamasa/image/upload/v1568779719/riser-sizer-bg.svg);
	background-size: 100%;
	background-position: 0% 0%;
	background-repeat: no-repeat;
}

.NewProject form {
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  height: unset;
  margin: 20vh auto;
  align-items: flex-start;
  background-color: white;
  padding: 100px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,.5);
}

.NewProject h1 {
	margin-bottom: 50px;
	font-weight: bold;
	color: #444;
}

.NewProject .form-control {
  border: none;
  box-shadow: none;
  background-color: #eee;
  border-bottom: 2px solid #c8d6e5;
  box-sizing: border-box;
  margin: 0;
}

.NewProject .form-control:focus {
  background-color: #DEE8FE;
}

.NewProject form text {
  height: 300px;
  font-size: 24px;
}

.NewProject__buttons {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0;
}

.NewProject__cancel {
	font-size: 1.4rem;
}

.building-settings {
	width: 100%;
}	

.building-settings__button {
	width: 40px; height: 40px;
	border-radius: 5px;
	background-color: #eee;
	border: 2px solid #bbb;
	font-size: 2rem;
	color: #555;
	box-sizing: border-box;
	padding-top: 5px;
}

.building-settings__button * {
	pointer-events: none;
}

.building-settings__enable {
	margin-bottom: 50px;
}

.building-settings__content {
	border-top: 2px solid #ccc;
	padding: 50px 0;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.building-settings__group {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

}

.building-settings__group .building-settings__item {
	flex: 45% 0 1;
}

.building-settings__item {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.building-settings__item .form-group {
	height: auto!important;
}
 
.building-settings__description {
	font-size: 1.6rem;
	color: #777;
	flex: 100% 1 1;
}

.control-label {
	font-size: 1.6rem;
}
