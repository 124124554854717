.BillingFormContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  padding-top: 50px;
  padding-bottom: 20px;
  position: relative;
}

.BillingForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.BillingForm h1 {
  width: 100%;
  text-align: left;
}

.BillingForm .card-field {
  margin-bottom: 15px;
  background-color: white;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #CCC;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  line-height: 1.3333333;
  width: 100%;
}

.BillingForm .card-field.StripeElement--focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  border-color: #66AFE9;
}

.control-label {
	text-align: left;
	width: 100%;
}

.BillingForm .LoaderButton {
  width: auto;
  padding: 10px 20px;
  align-self: flex-end;
  background-color: #4d83fe;
  color: white;
  margin: 20px 0;
}

.BillingForm .LoaderButton:disabled {
  background-color: #849DD8;
  color: #eee;
}

.BillingForm__cancel {
  margin: 0 auto;
  height: auto; 
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  position: absolute; 
  top: 0;
  right: 1rem;
  font-size: 3rem;
}
