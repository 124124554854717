.overview__toolbar {
	width: 100%;
	height: 8vh;
	max-height: 150px;
	min-height: 60px;
	display: flex;
	padding: 0;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: flex-end;
	background-color: #ecf0f1;
	max-width: 100%;
	width: 90%;
	margin: 0 auto;
	flex-shrink: 0;
}

.overview__toolbar-wrapper {
	height: 80%;
	width: 100%;
	box-sizing: border-box;
	display: flex;	
	flex: 100% 1 1;
	justify-content: space-between;
}

.overview__back {
	width: 70px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	color: #333;
	box-sizing: border-box;
	line-height: 70px;
}

.overview__header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.overview__heading {
	margin: 0;
	font-size: 3rem;
	font-family: "Lato", sans-serif;
	font-weight: bold;
	height: 50px;
	display: flex;
	align-items: center;
}

.overview__subheading {
	color: #8D9698;
	font-size: 1.4rem;
}

form {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
	height: 100%;
}

.form-group {
	height: 100%;
	margin: 0;
}

.form-control {
	height: 100%;
}

.overview__buttons {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: space-between;
}

.overview__buttons-wrapper {
	height: 50px;
	position: relative;
}

.overview__toolbar__button {
	padding: 0;
	height: 100%;
}

.overview__button--delete {
	background-color: transparent;
	height: 50px; width: 50px;
	border: none;
	font-size: 2rem;
	margin: 0;
	margin-top: -10px;
	padding: 0;
	margin-left: 50px;
	color: #777;
}

.overview__button--delete:hover {
	color: white;
	background-color: #e17055;
}

.overview__button--delete:focus {
	background-color: transparent;
	outline: none;
}

.overview__toolbar__button {
	/*width: 8vw;*/
	height: 50px;
	font-size: 2rem;
	font-family: "Lato", sans-serif;
	/*box-shadow: none;	*/
	border-radius: 0;
	height: 50px;
	background-color: transparent;
	border: 0;
	color: #555;
	padding: 0 10px;
	margin-left: -10px;
	border-radius: 5px;
}

.overview__toolbar__button:hover {
	cursor: pointer;
	color: white;
	background-color: #4d83fe;
}