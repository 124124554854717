.floor {
	min-height: 150px;
	box-sizing: border-box;
}

.floor--active {
  background-color: white;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(150,150,150,1);
  -moz-box-shadow: 0px 0px 5px 2px rgba(150,150,150,1);
  box-shadow: 0px 0px 5px 2px rgba(150,150,150,1);
  position: relative;
  z-index: 2;
  border-radius: 5px;
}

.floor--selected {
	background-color: #CEDDFE;
	position: relative;
}