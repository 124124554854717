.riser-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  top: 0;
  margin-top: 0;
  width: 90%;
  padding: 0 10px;
  margin: 0 auto;
  align-self: flex-end;
  /*overflow-y: visible;*/
	padding: 0;
	border-radius: 5px;
	flex-grow: 1;
	height: unset;
	margin-bottom: 50px;
}

.riser-list--empty {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 5rem;
	box-sizing: border-box;
	margin: 0;
	color: rgb(200,200,200);
}