.riser-item {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  /*top: 160px;*/
  background-color: white;
  -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
     -moz-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
}

.riser-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.riser-item:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}