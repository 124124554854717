@font-face {
  font-family: "line-awesome";
  src: url("/line-awesome/line-awesome/fonts/line-awesome.ttf");
}

@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');

 html, body {
  height: 100%;
  width: 100vw;
  min-height: 0;
  font-size: 10px;
  font-family: "line-awesome";
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /*overflow-y: hidden;*/
}

.navbar {
  height: 50px;
  min-height: 50px;
  margin: 0;
  position: relative;
  z-index: 2;
  background-color: white;
}

.navbar::before {
  content: unset;
}

.navbar__wrapper {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.navbar__brand {
  font-size: 2rem;
  margin: 0;
  color: white;
  font-weight: bold;
  letter-spacing: -0.25rem;
}

.navbar__list > li {
  margin-left: 20px;
}

.navbar__list > li > a {
  color: white;
}

.Overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
  background-color: #ecf0f1;
}

.toolbar__heading {
  margin-top: 0;
}

.overview__toolbar__button:focus {
  outline: none;
}

.overview__toolbar__button--group:after {
  position: absolute;
  content: " ";
  border: 4px dashed white;
  border-radius: 5px;
  width: 18%;
  height: 60%;
}

.toolbar__heading {
  font-size: 3rem;
  font-family: sans-serif;
  text-align: left;
}

.Sidebar {
  width: 20%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  min-width: 300px;
  position: relative;
  z-index: 1000;
  justify-content: flex-start;
  align-items: center;
  background-color: white; 
  border-radius: 5px 0 0 5px;
  overflow: scroll;
  -webkit-box-shadow: -2px 0px 5px 0px rgba(120,120,120,1);
  -moz-box-shadow: -2px 0px 5px 0px rgba(120,120,120,1);
  box-shadow: -2px 0px 5px 0px rgba(120,120,120,1);
} 

.Sidebar__item {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0;
  height: 7.5vh;
  max-height: 80px;
  background-color: transparent;
  /*border-bottom: 1px solid #247EC2;*/
  box-sizing: border-box;
}

.fixture-button {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: #212121;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Sidebar__item:hover {
  cursor: pointer;
  background-color: #D7DBDC;
  box-sizing: border-box;
}

.fixture-button:hover {
  cursor: pointer;
}

.fixture-button:focus {
  outline: none;
}

.fixture-button * {
  margin: 5px 0;
  width: 50%;
  text-align: left;
}

.fixture-button__label {
  font-size: 1.4rem;
  width: 100%;
  text-align: left;
}

.editor {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh; 
  min-height: 0;
  flex-grow: 1;
}

.editing-area {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  /*padding: 40px;*/
  height: 100vh;
  min-height: 0;
  overflow: hidden; 
  min-height: 0;
}

.toolbar {
  /*position: fixed;*/
  z-index: 100;
  width: 100px;
  min-width: 100px;
  background-color: white;
  top: 0;
  left: 0;
  box-sizing: border-box;
  /*padding: 20px 0;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  -webkit-box-shadow: 1px 0px 5px 0px rgba(120,120,120,1);
  -moz-box-shadow: 1px 0px 5px 0px rgba(120,120,120,1);
  box-shadow: 1px 0px 5px 0px rgba(120,120,120,1);
}

.toolbar__button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toolbar__button {
  background-color: transparent;
  border: none;
  font-weight: bold;
  /*margin-bottom: 50px;*/
  height: 10vh;
  max-height: 80px;
  color: #3C3C3C; 
  font-size: 3rem;
}

.toolbar__button:hover {
  cursor: pointer;
  border-left: 3px solid #0D5E9A;
  color: #0D5E9A;
}

.toolbar__button:focus {
  outline: none;
}

.toolbar__button--add {
  
}

.toolbar__button--back {
  margin-bottom: 50px;
  border-bottom: 2px solid #eee;
}

.risers-container {
  flex-grow: 1;
  width: 90%;
  margin: 0 auto;
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  margin: 10px auto;
}

.scroll-button {
  border-radius: 5px;
  border: none;
  font-size: 3rem;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.scroll-button:hover {
  color: blue;
  cursor: pointer;
}

.scroll-button--right {
  position: fixed;
  z-index: 10000;
  right: 1.25%;
  top: 50%;
}

.scroll-button--left {
  position: fixed;
  z-index: 10000;
  left: 1.25%;;
  top: 50%;
}

.riser-wrapper {
  flex-grow: 1;
  background-color: #f7f7f7;
  overflow-y: scroll;
  background-color: #ecf0f1;
  padding: 30px;

}

.riser {
  display: flex;
  flex-direction: column-reverse;
  list-style-type: none;
  justify-content: center;
  flex-grow: 1;
  padding: 0;
  /*position: relative;*/
  /*z-index: 100;*/
  /*flex: 1 1 1000px;*/
  /*margin-bottom: 50px;*/
  overflow-y: scroll;
  margin: 0;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(120,120,120,1);
     -moz-box-shadow: 1px 1px 5px 0px rgba(120,120,120,1);
          box-shadow: 1px 1px 5px 0px rgba(120,120,120,1);
  position: relative;
}

/*.riser-editing-area {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: hidden;
  position: relative;
  height: 100%;
}*/

.floor-lines {
  width: 100%;
  position: absolute;
  top: 117px;
  box-sizing: border-box;
  overflow: hidden;
  /*margin-top: 10px;*/
  position: sticky;
  left: 0;
  top: 0;
  height: 100%;
}

.floor-line {
  width: 100%;
  height: 100px;
  max-height: 100px;
  border-bottom: 1px solid lightgrey;
  box-sizing: border-box;
  max-height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;
}

.floor-line__label {
  margin: 0;
  margin-top: 1px;
  font-size: 1.5rem;
  color: #333;
  font-family: sans-serif;
  width: 75px;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  z-index: 5000;
}

.riser__toolbar-wrapper {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  /*position: absolute;*/
  overflow-x: hidden;
  min-height: 150px;
  top: 10px;
  z-index: 1000;
}

.label__text {
  margin: 0;
}

.floor {
  border-bottom: 2px solid grey;
  display: flex;
  align-items: flex-end;
  min-height: 120px;
  /*height: 7.5vh;*/
  /*height: auto;*/
  /*height: 100%;*/
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #F4F5F5;
}

.floor--source {
  border-bottom: 2px solid red;
}

.floor__total-info {
  width: 100px;
  /*height: 100%; */
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  box-sizing: border-box;
}

.floor__main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /*border-left: 4px solid red;*/
  height: 100%;
  width: 100%;
  min-height: 150px;
  padding: 10px 0;
  box-sizing: border-box;
  position: relative;
}

.floor__input {
  width: 100%;
  text-align: right;
}

.floor__toolbar {
  /*position: absolute;
  right: 0;
  top: 0;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /*height: 100%;*/
  padding: 5px;
  box-sizing: border-box;
  width: 7vw;
  max-width: 120px;
  min-height: 100px;
  align-self: stretch;
  z-index: 10;
}

.floor__toolbar__button {
  font-size: 2rem;
  background-color: transparent;
  border: none;
  color: #ACAFB0;
}

.floor__toolbar__button:hover {
  cursor: pointer;
  color: #0D5E9A;
}

.floor__toolbar__button--hot:hover {
  color: red;
}

.floor--cold-source .floor__toolbar__button--cold {
  color: blue;
}

.floor--hot-source .floor__toolbar__button--hot {
  color: red;
}

.floor__total-info {
  /*height: 100%;*/
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
  padding-bottom: 5px;
  width: 5vw;
  max-width: 150px;
}

.floor__total-info__helper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-grow: 1;
}

.floor__total-info__values {
  margin: 0;
  /*margin-bottom: 20px;*/
  font-size: 1.4rem;
}

.floor__total-info__column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;  
  max-height: 120px;
}

.toolbar__info {
  display: flex;
  flex-direction: column;
}

.toolbar__info__text {
  margin: 0;
  font-size: 1.5rem;
}

.toolbar__info__text--cold {
  color: #3867d6;
}

.toolbar__info__text--hot {
  color: #d63031;
}

.toolbar__info__text--total {
  color: #6c5ce7;
}

.floor-fixtures {
  display: flex;
  flex-direction: row;
  height: 100%;
  /*width: 100%;*/
  flex-grow: 1;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-top: 10px;
  position: relative;
  min-height: 100px;
}

.fixture {
  /*height: 100%;*/
  height: 7.5vh;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 10px;
}

.fixture * {
  margin: 0;
}

.fixture__label {
  font-size: 1.6rem;
} 

.fixture__info {
  font-size: 2rem;
}